<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('trainers.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/trainer'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('trainers.create')"
        createRouteName="contributor.trainerCreate"
        :excelFields="excelFields"
        excelName="trainers"
        :downloadSampleStatus="true"
        downloadSampleUrl="trainer/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="trainer/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import TrainerFilter from "../models/TrainerFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new TrainerFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "contributor.trainerView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "contributor.trainerUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("trainers.item"),
              textContent: "name",
              url: "admin/trainer",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  mounted() {
    core.index();
  },
};
</script>
